
import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import Axios for making API request
import * as XLSX from 'xlsx';
// import loadingGif from './public/loading.gif'
import DashboardIndex from './DashboardIndex';



const Analytics = () => {
  // Define state for dropdown values and loading state
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [options3, setOptions3] = useState([]);
  const [selectedOption1, setSelectedOption1] = useState('');
  const [selectedOption2, setSelectedOption2] = useState('');
  let [selectedOption3, setSelectedOption3] = useState('');
  const [loadingOptions2, setLoadingOptions2] = useState(false);
  const [loadingOptions3, setLoadingOptions3] = useState(false);
  const [originalResult, setOriginalResult] = useState('');
  const [filteredResult, setFilteredResult] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const [totalTimeSpent, setTotalTimeSpent] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [maxProductName, setMaxProductName] = useState('');
  const [maxUserId, setMaxUserId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [uniqueOptions, setUniqueOptions] = useState([]);
  const [endDate, setEndDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    document.title = 'Trezi Dashboard'; // Set the desired page title
  }, []);

  const fetchOptions1 = async () => {
    try {
      const response = await fetch("https://lensservice.trezi.com/lensdataservice/api/v1/lens/moduleIds");
      const data = await response.json();
      
      if (Array.isArray(data)) {
        setOptions1(data);
      } else {
        console.error('Failed to fetch module IDs.');
      }
    } catch (error) {
      console.error('Error fetching module IDs:', error);
    }
  };

  const handleDropdownChange1 = async (event) => {
    setSelectedOption1(event.target.value);
    setSelectedOption2('');
    setSelectedOption3('');
    setOriginalResult('');
    setFilteredResult('');
    fetchOptions2(event.target.value);
  };

  const fetchOptions2 = async () => {
    try {
      const response = await fetch(`https://lensservice.trezi.com/lensdataservice/api/v1/lens/functionalityIds`);
      // const response = await fetch(`http://125.63.103.147:8080/analytics-service/api/v2/functionalityId/${selectedOption1}`);
      const data = await response.json();
     
      if (Array.isArray(data)) {
        const filteredFunctionalityIds = data
          .filter(item => item !== "LensTeleportHostData" && item !== "LensTeleportAttendeeData")
          .map((item, index) => ({ id: index, label: item }));
  
        // console.log(filteredFunctionalityIds);
        setOptions2(filteredFunctionalityIds);
      } else {
        console.error('Failed to fetch functionality IDs.');
      }
      setLoadingOptions2(false);
    } catch (error) {
      console.error('Error fetching functionality IDs:', error);
      setLoadingOptions2(false);
    }
  };
  
  
  const handleDropdownChange2 = (event) => {
    setSelectedOption2(event.target.value);
    setSelectedOption3('');
    setOriginalResult('');
    setFilteredResult(''); // Reset selectedOption3
    // Call fetchOptions3 with selectedOption1 and event.target.value (selectedOption2)
    fetchOptions3(selectedOption1, event.target.value);
  }
  

  const fetchOptions3 = async (selectedOption1, selectedOption2) => {
    setLoadingOptions3(true);
  
    // Check if both options are provided
    if (!selectedOption1 || !selectedOption2) {
      console.error('Both selectedOption1 and selectedOption2 must be set');
      setLoadingOptions3(false);
      return;
    }
  
    let endpoint = '';
    let endpoint2 = '';

    // Determine the endpoint based on selected options
    if (selectedOption1 === 'TreziLauncher' && selectedOption2 === 'LauncherSession') {
      endpoint = 'https://lensservice.trezi.com/analytics-service/api/v3/launchermail?';
      endpoint2 = 'https://showcaseapi.trezi.com/uus/api/v1/users/login/data/emails';
    } else {
      endpoint = 'https://lensservice.trezi.com/lensdataservice/api/v1/lens/userIds';
    }


    try {
      const [response1, response2] = await Promise.all([
        axios.get(endpoint),
        endpoint2 ? axios.get(endpoint2) : Promise.resolve({ data: [] })
        
      ]);

      

      console.log('API response from endpoint:', response1.data);
      if (response2) {
        console.log('API response from endpoint2:', response2.data);
      }
  
      let userIds1 = [];
      let userIds2 = [];
  
      if (selectedOption1 === 'TreziViewer') {
        if (response1 && Array.isArray(response1.data)) {
          userIds1 = response1.data
            .map(email => ({
              id: email,
              label: email,
            }))
            .filter(item => item.id && item.label);
        } else {
          console.error('Failed to fetch user IDs from endpoint.');
        }
      } else {
        if (response1.data && response1.data.success && response1.data.data) {
          userIds1 = response1.data.data
            .map(item => ({
              id: item.email,
              label: item.email,
            }))
            .filter(item => item.id && item.label);
        } else {
          console.error('Failed to fetch user IDs from endpoint.');
        }
  
        if (response2 && Array.isArray(response2.data)) {
          userIds2 = response2.data
            .map(email => ({
              id: email,
              label: email,
            }))
            .filter(item => item.id && item.label);
        } else if (response2) {
          console.error('Failed to fetch user IDs from endpoint2.');
        }
      }
  
      // Combine and ensure unique keys
      const combinedUserIds = [...userIds1, ...userIds2];
      const uniqueUserIds = Array.from(new Set(combinedUserIds.map(item => item.id)))
        .map(id => combinedUserIds.find(item => item.id === id));
  
      uniqueUserIds.sort((a, b) => a.label.localeCompare(b.label));
      setOptions3(uniqueUserIds);
  
    } catch (error) {
      console.error('Error fetching user IDs:', error);
      setOptions3([]); // Reset options3 on error
    } finally {
      setLoadingOptions3(false);
    }
  };
  
  
  
// Handle changes in selectedOption1 and selectedOption2
useEffect(() => {
  if (selectedOption1 && selectedOption2) {
    fetchOptions3(selectedOption1, selectedOption2);
  }
}, [selectedOption1, selectedOption2]);

// Handle change for User ID dropdown
 const handleDropdownChange3 = (event) => {
    setSelectedOption3(event.target.value);
  };


  useEffect(() => {
    // Create a Set to filter out duplicate entries
    const uniqueOptionsSet = new Set();
    const filteredOptions = options3.filter(option => {
      const isDuplicate = uniqueOptionsSet.has(option.label);
      uniqueOptionsSet.add(option.label);
      return !isDuplicate;
    });
    // Sort the filtered unique options alphabetically
    const sortedOptions = filteredOptions.sort((a, b) => a.label.localeCompare(b.label));
    setUniqueOptions(sortedOptions);
  }, [options3]);




  // Function to handle start date change
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

   // Function to handle end date change
   const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  }; 

    
  const handleShowResult = async () => {
    try {
      setIsLoading(true);
      let apiUrl = '';
      let apiUrl1 = '';
      let ViewerUrl = '';
  
      if (selectedOption1 === 'TreziLauncher' && selectedOption2 === 'LauncherSession') {
        console.log('launcher');
        apiUrl = 'https://lensservice.trezi.com/analytics-service/api/v3/launcherdetails?';
        apiUrl1 = 'https://showcaseapi.trezi.com/uus/api/v1/users/login/data?';
      } else {
        console.log('viewer');
        ViewerUrl = 'https://lensservice.trezi.com/lensdataservice/api/v1/lens/data?';
      }
  
      console.log('URL', ViewerUrl);
      if (!selectedOption2) {
        alert('Please choose functionality Id');
        setIsLoading(false);
        return;
      }
  
      if (selectedOption1) {
        if (apiUrl) {
          apiUrl += `moduleId=${selectedOption1}`;
        } else {
          ViewerUrl += `&moduleId=${selectedOption1}`;
        }
      }
  
      if (selectedOption2) {
        if (apiUrl) {
          apiUrl += `&functionalityId=${selectedOption2}`;
        } else {
          ViewerUrl += `&functionalityId=${selectedOption2}`;
        }
      }
  
      if (selectedOption3) {
        const userIdParam = selectedOption3 === 'All' ? 'all' : selectedOption3 === 'NonTrezi' ? 'non-trezi' : selectedOption3;
  
        if (apiUrl) {
          apiUrl += `&userIds=${userIdParam}`;
        }
  
        if (apiUrl1) {
          apiUrl1 += `&email=${userIdParam}`;
        } else {
          ViewerUrl += `&email=${userIdParam}`;
        }
      }
  
      if (startDate && endDate) {
        if (selectedOption1 === 'TreziLauncher' && selectedOption2 === 'LauncherSession') {
          apiUrl += `&startDate=${startDate}&endDate=${endDate}`;
          apiUrl1 += `&startDate=${startDate}&endDate=${endDate}`;
        } else {
          ViewerUrl += `&startTime=${startDate}&endTime=${endDate}`;
        }
      }
  
      console.log(`Fetching data from API: ${apiUrl}`);
      console.log(`Fetching data from API1: ${apiUrl1}`);
      console.log(`Fetching data from ViewerUrl: ${ViewerUrl}`);
  
      const response1 = apiUrl ? await axios.get(apiUrl) : { data: { data: [] } };
  
      const [response2, response3] = await Promise.all([
        apiUrl1 ? axios.get(apiUrl1) : Promise.resolve({ data: { data: [] } }),
        ViewerUrl ? axios.get(ViewerUrl) : Promise.resolve({ data: { data: [] } })
      ]);
  
      const data1 = Array.isArray(response1.data.data) ? response1.data.data : [];
      const data2 = Array.isArray(response2.data) ? response2.data : [];
      const data3 = Array.isArray(response3.data) ? response3.data : [];
  
      console.log("data from launcher api", data1);
      console.log("data from new launcher api", data2);
      console.log("data from Viewer api", data3);
  
      const normalizedData2 = data2.map(item => ({
        ...item,
        keys: {
          ...item.keys,
          productName: item.keys?.productName || 'Lens'
        }
      }));
  
      const normalizedData3 = data3.map(item => ({
        ...item,
        keys: {
          ...item.keys,
          productName: item.keys?.productName || 'Lens'
        }
      }));
  
      var combinedData = [...data1, ...normalizedData2, ...normalizedData3];
  
      const getDateFromDateTime = (dateTime) => {
        const date = new Date(dateTime);
        return date.toISOString().split('T')[0]; // Extracts YYYY-MM-DD
      };
  
      if (selectedOption1 === 'TreziLauncher' && selectedOption2 === 'LauncherSession') {
        combinedData.sort((a, b) => (a.email < b.email ? -1 : a.email > b.email ? 1 : 0));
  
        const groupedEntries = combinedData.reduce((acc, entry) => {
          const email = entry.email;
          const date = getDateFromDateTime(entry.loginTime);
  
          if (!acc[email]) {
            acc[email] = {};
          }
  
          if (!acc[email][date]) {
            acc[email][date] = [];
          }
  
          acc[email][date].push(entry);
          return acc;
        }, {});
  
        Object.keys(groupedEntries).forEach((email) => {
          Object.keys(groupedEntries[email]).forEach((date) => {
            const entries = groupedEntries[email][date];
  
            if (entries.length > 1) {
              for (let i = 0; i < entries.length; i++) {
                const currentEntry = entries[i];
                const nextEntry = entries[i + 1];
  
                if (nextEntry && currentEntry.logOutTime === null) {
                  currentEntry.logOutTime = nextEntry.loginTime;
                }
  
                if (currentEntry.loginTime && currentEntry.logOutTime && currentEntry.timeDuration === null) {
                  const loginTimeEpoch = new Date(currentEntry.loginTime).getTime();
                  const logOutTimeEpoch = new Date(currentEntry.logOutTime).getTime();
                  const duration = logOutTimeEpoch - loginTimeEpoch;
                  currentEntry.timeDuration = Math.round(duration / 1000);
                }
              }
            } 
          });
        });
      } else {
        combinedData.sort((a, b) => (a.email < b.email ? -1 : a.email > b.email ? 1 : 0));
  
        for (let i = 0; i < combinedData.length; i++) {
          const currentEntry = combinedData[i];
          const nextEntry = combinedData[i + 1];
  
          if (nextEntry && currentEntry.email === nextEntry.email && currentEntry.logOutTime === null) {
            currentEntry.logOutTime = nextEntry.loginTime;
          }
  
          if (currentEntry.loginTime && currentEntry.logOutTime && currentEntry.timeDuration === null) {
            const loginTimeEpoch = new Date(currentEntry.loginTime).getTime();
            const logOutTimeEpoch = new Date(currentEntry.logOutTime).getTime();
            const duration = logOutTimeEpoch - loginTimeEpoch;
            currentEntry.timeDuration = Math.round(duration / 1000);
          }
        }
      }
  
      if (combinedData.length > 0) {
        // Apply the condition to filter out the specific entry for userId 'ankitagupta@idesignspaces.in'
        if (selectedOption1 === 'TreziViewer') {
          console.log("Inside if");
          if(startDate === '2024-08-05' && endDate ==='2024-08-05'){
            combinedData = combinedData.filter(data => {
              // Return true if it's NOT the specific entry for the given userId
             
              return data.userId !== 'ankitagupta@idesignspaces.in';
          });
          }
     
      }
      
      
        setOriginalResult(combinedData);
        console.log('Final fetch result:', combinedData);
      
        const firstDataItem = combinedData[0];
      
        if (firstDataItem && firstDataItem.keys && ViewerUrl) {
          const dataKeys = Object.keys(firstDataItem.keys);
          setFilterOptions(dataKeys);
        } else {
          console.error('First data item or its keys are undefined');
          setFilterOptions([]);
        }
      
        let productNameCounts = {};
        let maxProductName = '';
        let maxProductNameCount = 0;
        let maxUserId = '';
        let maxUserIdCount = 0;
        let userIdCounts = {};
      
        combinedData.forEach(item => {
          const productName = item.keys?.productName;
          const userId = item.userId;
      
          if (productName) {
            productNameCounts[productName] = (productNameCounts[productName] || 0) + 1;
            if (productNameCounts[productName] > maxProductNameCount) {
              maxProductNameCount = productNameCounts[productName];
              maxProductName = productName;
            }
          }
      
          if (userId !== undefined) {
            userIdCounts[userId] = (userIdCounts[userId] || 0) + 1;
            if (userIdCounts[userId] > maxUserIdCount) {
              maxUserIdCount = userIdCounts[userId];
              maxUserId = userId;
            } else if (userIdCounts[userId] === maxUserIdCount) {
              maxUserId += `, ${userId}`;
            }
          }
        });
      
        setMaxProductName(maxProductName);
        setMaxUserId(maxUserId);
      
        let totalTimeSpentTrue = 0;
        let totalTimeSpentFalse = 0;
        let totalTimeSpentOther = 0;
        let trueCount = 0;
        let falseCount = 0;
      
        combinedData.forEach(item => {
          const timeSpentSeconds = parseFloat(item.keys?.timeSpent);
          if (!isNaN(timeSpentSeconds)) {
            if (item.keys.isVRConnected === 'TRUE') {
              totalTimeSpentTrue += timeSpentSeconds;
              trueCount++;
            } else if (item.keys.isVRConnected === 'FALSE') {
              totalTimeSpentFalse += timeSpentSeconds;
              falseCount++;
            } else {
              totalTimeSpentOther += timeSpentSeconds;
            }
          } else {
            console.error(`Invalid timeSpent value: ${item.keys?.timeSpent}`);
          }
        });
      
        const totalTimeMinutes = totalTimeSpentTrue + totalTimeSpentFalse + totalTimeSpentOther;
        const totalHours = Math.floor(totalTimeMinutes / 60);
        const totalDays = Math.floor(totalHours / 24);
        const remainingHours = totalHours % 24;
      
        setTotalTimeSpent({
          totalTrue: totalTimeSpentTrue,
          totalFalse: totalTimeSpentFalse,
          totalOther: totalTimeSpentOther,
          totalTime: totalTimeMinutes,
          trueCount: trueCount,
          falseCount: falseCount,
          totalHours: totalHours,
          totalDays: totalDays,
          remainingHours: remainingHours
        });
      }
      
       else {
        console.log('No data returned from the API');
        alert("No data found for specific date");
        setFilterOptions([]);
        setMaxProductName('');
        setMaxUserId('');
        setTotalTimeSpent({
          totalTrue: 0,
          totalFalse: 0,
          totalOther: 0,
          totalTime: 0,
          trueCount: 0,
          falseCount: 0,
          totalHours: 0,
          totalDays: 0,
          remainingHours: 0
        });
        window.location.reload();
      }
    } catch (error) {
      console.error('Error fetching result:', error);
      alert('Error fetching data. Please try again later.');
      window.location.reload();

      
    } finally {
      setIsLoading(false);
    }
  };
  
  
  


const formatTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);
  return `${hours}hr, ${minutes}min, ${seconds}sec`;
};

  useEffect(() => {
    fetchOptions1();
  }, []);

  useEffect(() => {
    if (originalResult && selectedFilter) {
      const filteredResult = originalResult.map(data => ({
        moduleId: data.moduleId,
        functionalityId: data.functionalityId,
        userId: data.userId,
        sessionId: data.sessionId,
        startTime: data.startTime,
        endTime: data.endTime,
        keys: Object.keys(data.keys)
          .filter(key => key === selectedFilter)
          .reduce((obj, key) => {
            obj[key] = data.keys[key];
            return obj;
          }, {})
      }));
      setFilteredResult(filteredResult);
    } else {
      setFilteredResult(originalResult);
    }
  }, [originalResult, selectedFilter]);

  

  const exportToExcel = () => {
    // Get the table element
    const table = document.querySelector('.result-table');
    
    if (table) {
      // Initialize an empty array to store the data
      const data = [];
      
      // Extract headings from the table
      const headings = [];
      table.querySelectorAll('th').forEach(th => {
        headings.push(th.textContent);
      });
      data.push(headings);
  
      // Iterate over each row in the table
      table.querySelectorAll('tr').forEach(row => {
        const rowData = [];
        
        // Iterate over each cell in the row
        row.querySelectorAll('td').forEach(cell => {
          // Push the cell's text content to the rowData array
          rowData.push(cell.textContent);
        });
        
        // Push the rowData array to the data array
        data.push(rowData);
      });
  
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      
      // Convert the data array to a worksheet
      const ws = XLSX.utils.aoa_to_sheet(data);
      
      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      // Write the workbook to a file
      XLSX.writeFile(wb, 'exported_data.xlsx');
    } else {
      console.error('No data available to export.');
    }
  };
  


  // Names change for ModuleId options
  const getOptionLabel1 = (moduleId) => {
    switch (moduleId) {
      case 'TreziViewer':
        return 'LensViewer';
      case 'TreziLauncher':
        return 'LensLauncher';
        default:
          return  moduleId;  
    }
  }

  const getOptionValue1 = (moduleId) => {
    switch (moduleId) {
      case 'LensViewer':
        return 'TreziViewer';
        case 'LensLauncher':
          return 'TreziLauncher'
        default:
          return moduleId;  
    }
  }
  
  // Name change for FunctionalityId Options
  const getOptionLabel = (label) => {
    switch (label) {
      case 'LensSessionData':
        return 'ViewerSession';
      // case 'LensTeleportHostData':
      //   return 'LensCollabHostData';
      case 'LensApplicationModeData':
        return 'VR/Desktop';
      // case 'LensTeleportAttendeeData':
      //   return 'LensCollabAttendeeData';
        case 'LensShowcaseObjectData':
          return 'ShowcaseObject';
      default:
        return label;
    }
  };

  const getOptionValue = (label) => {
    switch (label) {
      case 'ViewerSession':
        return 'LensSessionData';
      // case 'LensCollabHostData':
      //   return 'LensTeleportHostData';
      case 'VR/Desktop':
        return 'LensApplicationModeData';
      //  case 'LensCollabAttendeeData':
      //   return'LensTeleportAttendeeData';
       case 'ShowcaseObject':
        return 'LensShowcaseObjectData';
      default:
        return label;
    }
  };

  const formatDateIST = (isoString) => {
    const date = new Date(isoString);
  
    // Convert UTC to IST (UTC +5:30)
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);
  
    // Format the date
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();
    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
    // Determine AM/PM suffix
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
  
    // Format hours to always have two digits
    const formattedHours = String(hours).padStart(2, '0');
  
    return `${month}/${day}/${year}, ${formattedHours}:${minutes}:${seconds} ${ampm}`;
  };
  
  
  

  const formatDateUTCSummary = (date) => {
    const utcDate = new Date(date);
    const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(utcDate.getUTCDate()).padStart(2, '0');
    const year = utcDate.getUTCFullYear();
    return `${month}/${day}/${year}`;
  };
  


const formatTimeDuration = (seconds) => {
  if (typeof seconds !== 'number' || seconds < 0) {
    return 'NULL';
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const formattedTime = `${hours}hr,${minutes.toString().padStart(2, '0')}min,${secs.toString().padStart(2, '0')}sec`;

  return formattedTime;
};

const formatDate = date => {
  const dateObj = new Date(date);
  return dateObj.getTime() === 0 ? 'null' : dateObj.toLocaleDateString();
};


  return (
    <>
    <DashboardIndex />
    <div className="App">
    {isLoading && (
      <div className="loading-container">
        <img src="/loading.gif" alt="Loading..." />
      </div>
    )}
    {!isLoading &&(
        <div className='full'>
        <div id='container'>
  <div className='model'>
    <h3 id="heading">Module</h3>
    <select className="select-dropdown" value={selectedOption1} onChange={handleDropdownChange1}>
      <option key="default" value="">Select</option>
      {options1.map((moduleId, index) => (
        <option key={index} value={getOptionValue1(moduleId)}>
          {getOptionLabel1(moduleId)}
        </option>
      ))}
    </select>
  </div>

  <div className='function'>
    <h3 id="heading">Functionality</h3>
    <select className="select-dropdown" value={selectedOption2} onChange={handleDropdownChange2} disabled={loadingOptions2}>
      <option key="default" value="">{loadingOptions2 ? 'Loading...' : 'Select'}</option>
      {!loadingOptions2 && selectedOption1 !== 'TreziLauncher' && options2.map((optionFunctionality, index) => (
        <option key={optionFunctionality.id || index} value={getOptionValue(optionFunctionality.label)}>
          {getOptionLabel(optionFunctionality.label)}
        </option>
      ))}
      {selectedOption1 === 'TreziLauncher' && (
        <option key="TreziLauncher" value="LauncherSession">LauncherSession</option>
      )}
    </select>
  </div>

  <div className='user'>
    <h3 id="heading">User ID</h3>
    <select className="select-dropdown" value={selectedOption3} onChange={handleDropdownChange3} disabled={loadingOptions3}>
      <option value="All">All</option>
      <option value="NonTrezi">Non Trezi</option>
      {options3.length > 0 ? (
        options3
          .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically
          .map(option => (
            <option key={option.id} value={option.id}>{option.label}</option>
          ))
      ) : (
        <option disabled>No options available</option>
      )}
    </select>
  </div>

  {originalResult && originalResult.length > 0 && (
    <>
      {selectedOption1 === 'TreziViewer' && (
        <div className="filter">
          <h3 id="heading">Filter</h3>
          <select className="select-dropdown" onChange={(e) => setSelectedFilter(e.target.value)}>
            <option value="">All</option>
            {filterOptions
              .sort((a, b) => a.localeCompare(b)) // Sort options alphabetically
              .map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
          </select>
        </div>
      )}
    </>
  )}
  
  <div className='show'>
    <button id='showbutton' onClick={handleShowResult} disabled={!selectedOption1 || !selectedOption2}>
      Show Result
    </button>
  </div>
  <div className='export'>
    <button id='exportbutton' onClick={exportToExcel} disabled={!filteredResult || filteredResult.length === 0}>
      Export to Excel
    </button>
  </div> 
</div>

         {/* </div> */}
        
    <div className="date-range">
      <h3 id="heading" >Select Date</h3>
      <div className='startDate'>
        <label>Start Date:    </label>
        <input type="date" value={startDate} onChange={handleStartDateChange} />
      </div>
      <div className="endDate">
        <label>End Date:      </label>
        <input type="date" value={endDate} onChange={handleEndDateChange} />
      </div>
    </div>
<div>
{originalResult && originalResult.length > 0 && (
  <>
{selectedOption1 === 'TreziLauncher' && (
  <div className='summary'>
    <h3 id="heading">Summary</h3>
    <div>
      <ul className='summarylist'>
        <li>Data From {filteredResult.length > 0 && (
          <>
            {/* {new Date(Math.min(...filteredResult.map(data => new Date(data.loginTime)))).toLocaleDateString()} to{' '}
            {new Date(Math.max(...filteredResult.map(data => new Date(data.logOutTime)))).toLocaleDateString()} */}
               {formatDate(Math.min(...filteredResult.map(data => new Date(data.loginTime))))} to{' '}
               {formatDate(Math.max(...filteredResult.map(data => new Date(data.logOutTime))))}

          </>
        )}</li>
        <li>Total time spent in LensLauncher: {filteredResult.length > 0 && formatTimeDuration(filteredResult.reduce((total, data) => total + data.timeDuration, 0))}</li>
      </ul>
    </div>
  </div>
)}

      {selectedOption1 === 'TreziViewer' && (
 <div className='summary'>
 <h3 id="heading">Summary</h3>
 <div>
   <ul className='summarylist'>
     {filteredResult.length > 0 && (
       <>

         <li>
           Data from : {formatDateUTCSummary(Math.min(...filteredResult.map(data => new Date(data.startTime))))} to{' '}
           {formatDateUTCSummary(Math.max(...filteredResult.map(data => new Date(data.endTime))))}
         </li>
         {/* <li>Minimum Date: {formatDateUTC(Math.min(...filteredResult.map(data => new Date(data.startTime))))}</li>
         <li>Maximum Date: {formatDateUTC(Math.max(...filteredResult.map(data => new Date(data.endTime))))}</li>
         <li>All Filtered Results:</li> */}
       </>
     )}
     {selectedOption2 !== 'LensShowcaseObjectData' && (
       <>
         <li>Total time Spent in Lens Viewer: {formatTime(totalTimeSpent.totalTime)}</li>
       </>
     )}
     {selectedOption2 === 'LensApplicationModeData' && (
       <>
         <li>Number of Times VR used: {totalTimeSpent.trueCount}</li>
         <li>Total time Spent in VR: {formatTime(totalTimeSpent.totalTrue)}</li>
         <li>Number of Times Desktop Mode: {totalTimeSpent.falseCount}</li>
         <li>Total time Spent in Desktop: {formatTime(totalTimeSpent.totalFalse)}</li>
       </>
     )}
     {selectedOption2 === 'LensShowcaseObjectData' && (
       <>
         <li>Maximum Product Name: {maxProductName}</li>
         <li>Maximum User ID: {maxUserId}</li>
       </>
     )}
   </ul>
 </div>
</div>


)}
</>
)}
</div>
<div className='analytics_result'>
  {selectedOption1 === 'TreziViewer' && Array.isArray(filteredResult) && filteredResult.length > 0 && (
    <>
      <div className='table_container'>
        <table className="result-table">
          <thead>
            <tr>
              <th>Module Id</th>
              <th>Functionality Id</th>
              <th>User Id</th>
              <th>sessionId</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Product Name</th>
              {filteredResult[0].keys && Object.keys(filteredResult[0].keys).map((key, index) => (
                key !== 'productName' && (
                  <th key={index}>{key}</th>
                )
              ))}
            </tr>
          </thead>
          <tbody>
            {[...filteredResult].sort((a, b) => new Date(b.startTime) - new Date(a.startTime)).map((data, index) => {
              const productName = data.product || 'Lens';
              const keys = data.keys || {};

              const startTime = formatDateIST(data.startTime);
              const endTime = formatDateIST(data.endTime);

              return (
                <tr key={index}>
                  <td align='center'>{data.moduleId}</td>
                  <td align='center'>{data.functionalityId}</td>
                  <td align='center'>{data.userId}</td>
                  <td align='center'>{data.sessionId}</td>
                  <td align='center'>{startTime}</td>
                  <td align='center'>{endTime}</td>
                  <td align='center'>{productName}</td>
                  {Object.keys(keys).map((key, keyIndex) => (
                    key !== 'productName' && (
                      <td align='center' key={keyIndex}>
                        {key === 'timeSpent' ? formatTime(keys[key]) : keys[key]}
                      </td>
                    )
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  )}
</div>


<div className='analytics-result-clone'>
  {selectedOption1 === 'TreziLauncher' && Array.isArray(filteredResult) && filteredResult.length > 0 && (
    <>
      <div className='other_table_container'>
        <table className="result-table">
          <thead>
            <tr>
              <th>Module Id</th>
              <th>Functionality Id</th>
              <th>User Id</th>
              <th>Login Date & Time</th>
              <th>Log Out Date & Time</th>
              <th>Time Duration</th>
              <th>Product Name</th>
            </tr>
          </thead>
          <tbody>
            {filteredResult
              .sort((a, b) => new Date(b.loginTime) - new Date(a.loginTime)) // Sorting by loginTime in descending order
              .map((data, index) => {
                const loginDate = new Date(data.loginTime).toLocaleString();
                const logOutDateRaw = new Date(data.logOutTime).toLocaleString();
                const logOutDate = logOutDateRaw === '1/1/1970, 5:30:00 AM' ? 'NULL' : logOutDateRaw;
                const productName = data.productName || 'Lens';

                return (
                  <tr key={index}>
                    <td align="center">LensLauncher</td>
                    <td align="center">LauncherSession</td>
                    <td align="center">{data.email}</td>
                    <td align="center">{loginDate}</td>
                    <td align="center">{logOutDate}</td>
                    <td align="center">{formatTimeDuration(data.timeDuration)}</td>
                    <td align="center">{productName}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  )}
</div>



      </div>
    )}
     </div>
    </>
  );
};

export default Analytics;