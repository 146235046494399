import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import DashboardIndex from './DashboardIndex';

const DailyUsers = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'https://lensservice.trezi.com/treziservice/api/v1/getconceptaisearcheddata';
        const response = await axios.get(endpoint);
        console.log(response.data); // Log the response to see its structure
        // Assuming response.data is an array or an object containing the array
        const result = Array.isArray(response.data) ? response.data : response.data.data || [];
        setData(result);
      } catch (error) {
        setError('Error making API call');
        console.error('Error making API call:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const convertEpochToDate = (epoch) => {
    const date = new Date(epoch);
    return date.toLocaleDateString();
  };

  const exportToExcel = () => {
    const headers = ['Date', 'User', 'Product', 'Search Data'];
    const worksheetData = [
      headers,
      ...data.map(item => [
        convertEpochToDate(item.searchedDate),
        item.searchedBy,
        item.productName || 'N/A',
        item.searchedData,
      ])
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Concept AI');
    XLSX.writeFile(workbook, 'ConceptAI_SearchData.xlsx');
  };

  const renderTable = () => {
    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    if (data.length === 0) return <p>No data available</p>;

    const headers = ['Date', 'User', 'Product', 'Search Data'];
    const rows = data.map((item, index) => (
      <tr key={index}>
        <td>{convertEpochToDate(item.searchedDate)}</td>
        <td>{item.searchedBy}</td>
        <td>{item.productName || 'N/A'}</td>
        <td>{item.searchedData}</td>
      </tr>
    ));

    return (
      <div>
       
        <h2 id="heading">Concept AI</h2>
        <div id="button">
        <button id="exportbutton" onClick={exportToExcel}>Export to Excel</button>
        </div>
       
        <div className='other_table_container'>
  <table className="result-table">
    <thead>
      <tr>
        {headers.map((header, index) => (
          <th key={index}>{header}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows
        .sort((a, b) => {
          const aDate = new Date(a.props.children[0].props.children); // Assuming the 4th column is the date field
          const bDate = new Date(b.props.children[0].props.children); // Adjust index based on the date column
          return bDate - aDate;
        })}
    </tbody>
  </table>
</div>

      </div>
    );
  };

  return (
    <>
        <DashboardIndex/>
    <div className='home-container'>
      <div className='options-container'>
        {/* No button required as data is fetched automatically */}
      </div>
      <div className="data-container">
        {renderTable()}
      </div>
    </div>
    </>

  );
};

export default DailyUsers;
