import "./financeportal.css";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Box } from '@material-ui/core';
import React, { useState, useEffect } from "react";
import {  Modal, Form , Dropdown , DropdownToggle , DropdownMenu} from 'react-bootstrap';
import Navbarfinbox from "../financePortal/navbarfinbox";
import ReactPaginate from 'react-paginate';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { baseUrlUser} from "../utils/common";
import { getAllVendorData ,getAllVendorTransiction ,deleteVendorbyId ,AddPayment , downloadVendorExcel,getAllVendorDatabyvendorId ,updateVendorData} from "../services/ProductsService";
import { Typography } from "antd";
import logolink  from "../assests/public/Group 3415 (1).svg";
import Delete  from "../assests/public/Delete.svg";
import Edit  from "../assests/public/Group (5).svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

const urlPayment = baseUrlUser;
const PaymentManagement = () => {
  const [VendorData, setVendorData] = useState([]);
  const [VendorDataforId, setVendorDataforId] = useState([]);
  const [show, setShow] = useState(false);
  const [vendorName, setVendorName] = useState('');
  const [vendorId, setVendorId] = useState('');
  const [amount, setAmount] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [purchaseDate, setPurchaseDate] = useState('');
  const [points, setPoints] = useState('');
  const [upperPageNumber, setUpperPageNumber] = useState(0);
  const [lowerPageNumber, setLowerPageNumber] = useState(0);  
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [time, setTime] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Returns YYYY-MM-DD
  };
  const handleClose = () => {
    resetFormFields();
    setShow(false);
  };

  const handleShow = () => {
    resetFormFields();
    setShow(true);
  };

  const resetFormFields = () => {
    setVendorName('');
    setVendorId('');
    setAmount('');
    setTransactionId('');
    setPurchaseDate('');
    setPoints('');
  };

  const handleCreatePayment = async () => {
    
    const newPayment = {
      vendorId,
      amount,
      transactionsId: transactionId,
      date: purchaseDate,
      points
    };
    let errors = [];

    if (!vendorId) errors.push('Vendor');
    if (!amount) errors.push('Amount');
    if (!transactionId) errors.push('Transaction ID');
    if (!purchaseDate) errors.push('Purchase Date');
    if (!points) errors.push('Purchased Points');
  
    if (errors.length > 0) {
      setErrorMessage(`Please fill in the following required fields: ${errors.join(', ')}.`);
      return;
    }
debugger;
    try {
      const response = await AddPayment(newPayment);
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Payment Created',
          text: 'The payment has been successfully created.',
        });
        fetchVendorData();
        fetchVendorTransiction();
        handleClose();
        setErrorMessage('')
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.date || response.data ||  'Failed to create payment.' ,
        });
        setErrorMessage('')
      }
    } catch (error) {
      console.error('Error creating payment:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while creating the payment.',
      });
      setErrorMessage('')
    }
  };

  const fetchVendorData = async () => {
    try {
      const response = await getAllVendorData();
      if (response.status === 200) {
        setVendorDataforId(response.data.reverse());
        console.log(response.data);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data ||  'Failed to fetch vendor data.',
        });
      }
    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };
  const handleDateClick = (e, inputId) => {
    document.getElementById(inputId).focus(); // Focus the input when any part is clicked
  };

  const fetchVendorTransiction = async () => {
    try {
      debugger;
      const response = await getAllVendorTransiction({ startDate, endDate });
      if (response.status === 200) {
        setVendorData(response.data.reverse());
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data || 'Failed to fetch vendor data.',
        });
      }
    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };

  useEffect(() => {
    document.title = 'Vendor-Details';
    fetchVendorData();
    fetchVendorTransiction();
  }, []);

  const handleUpperPageClick = ({ selected }) => {
    setUpperPageNumber(selected);
  };
  
  const handleLowerPageClick = ({ selected }) => {
    setLowerPageNumber(selected);
  };
  const handleResetFilters = () => {
    debugger;
    setStartDate('');
    setEndDate('');
   fetchVendorData()
  };
  
  // const vendorsToDisplay = VendorData.slice(
  //   pageNumber * itemsPerPage,
  //   (pageNumber + 1) * itemsPerPage
  // );
  // const vendorsToDisplayforpoints = VendorDataforId.slice(
  //   pageNumber * itemsPerPage,
  //   (pageNumber + 1) * itemsPerPage
  // );
  const vendorsToDisplay = VendorData.slice(
    upperPageNumber * itemsPerPage,
    (upperPageNumber + 1) * itemsPerPage
  );
  
  const vendorsToDisplayforpoints = VendorDataforId.slice(
    lowerPageNumber * itemsPerPage,
    (lowerPageNumber + 1) * itemsPerPage
  );
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };

  const handleVendorChange = (e) => {
    const selectedVendor = VendorDataforId.find(vendor => vendor.name === e.target.value);
    setVendorName(selectedVendor.name);
    setVendorId(selectedVendor.vendorId);
  };
  const handleDownloadVendorFile =async () => {
    debugger;
    try {
      const response = await downloadVendorExcel();
      if (!response.ok) {
        console.error('Error downloading Excel:');
      }else{
        Swal.fire({
          icon: 'success',
          title: 'Downloaded',
          text: 'File downloaded successfully.',
        });
      }

    } catch (error) {
      console.error('Error downloading Excel:', error);
    }
  };
  return (
    <div  className="box5">
     
        <Navbarfinbox />
        <div  className="p-4 mt-4" >
        <div className ="mt-2 layout">
        <TableContainer>
          <Table >
            <TableHead >
              <TableRow class="colorful-row">
              <TableCell></TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>Total Points</TableCell>
                <TableCell>Used Points</TableCell>
                <TableCell>Balance Points</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vendorsToDisplayforpoints && vendorsToDisplayforpoints.map((item , index) => (
                <TableRow key={index} className ='trezi-table'>
                   <TableCell></TableCell>
                  <TableCell>{item.name}</TableCell>
                <TableCell>{item.totalPoints}</TableCell>
                <TableCell>{item.usedPoints}</TableCell>
                <TableCell>{item.balancePoints}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    
<ReactPaginate
  pageCount={Math.ceil(VendorDataforId.length / itemsPerPage)}
  pageRangeDisplayed={2}
  marginPagesDisplayed={2}
  onPageChange={handleLowerPageClick}
  containerClassName="pagination"
  activeClassName="active"
/>
        </div>
        </div>
      <div className="p-4">
        <div className="row">
          <div className="col-6 mt-2 custom-font-20">
            Payment Management
          </div>
          <div className="col-6 mt-4 text-right">
          <Button className="tz-primary-btn grey btn btn-secondary mr-2" onClick={handleDownloadVendorFile}  >Download Excel</Button>
            <button className="tz-primary-btn btn btn-secondary mr-2" onClick={handleShow}>Add Payment</button>
          </div>
        </div>
        <div className="row">
          <div className="col-1 mt-2 custom-font-16">
            Filter by Date
          </div>
         
          {/* Start Date */}
          <div className="col-2 mt-2" onClick={(e) => handleDateClick(e, 'startDateInput')}>
            <input
              id="startDateInput"
              type="date"
              className="form-control"
              value={startDate}
              placeholder="from"
              max={getCurrentDate()} // Disable future dates
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>

          {/* End Date */}
          <div className="col-2 mt-2" onClick={(e) => handleDateClick(e, 'endDateInput')}>
            <input
              id="endDateInput"
              type="date"
              className="form-control"
              value={endDate}
              max={getCurrentDate()} // Disable future dates
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="to"
            />
          </div>
          <div className="col-7 mt-2 text-right">
            <Button
              className="tz-primary-btn btn btn-secondary mr-2"
              onClick={fetchVendorTransiction}
            >
              Apply Filter
            </Button>
            <Button className="tz-primary-btn btn btn-secondary mr-2" onClick={handleResetFilters}>
              Reset
            </Button>
          </div>
        </div>
        <div className="mt-2 layout">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow className="colorful-row">
                  <TableCell>Date & Time</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Vendors</TableCell>
                  <TableCell>Transaction Id</TableCell>
                  <TableCell>Purchased Points</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vendorsToDisplay && vendorsToDisplay.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{formatDate(item.date)}</TableCell>
                    <TableCell>{item.amount}</TableCell>
                    <TableCell>{item.vendorName}</TableCell>
                    <TableCell>{item.transactionsId}</TableCell>
                    <TableCell>{item.points}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <ReactPaginate
  pageCount={Math.ceil(VendorData.length / itemsPerPage)}
  pageRangeDisplayed={2}
  marginPagesDisplayed={2}
  onPageChange={handleUpperPageClick}
  containerClassName="pagination"
  activeClassName="active"
/>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Add Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            )}
          <Form>
            <div className="form-row">
              <Form.Group className="col-md-4 mb-3" controlId="formVendorName">
                <Typography>Vendor Name</Typography>
                <Form.Control
                  as="select"
                  value={vendorName}
                  onChange={handleVendorChange}
                >
                  <option value="">Select Vendor</option>
                  {VendorDataforId.map((vendor) => (
                    <option key={vendor.vendorId} value={vendor.name}>
                      {vendor.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="col-md-4 mb-3" controlId="formAmount">
                <Typography>Amount</Typography>
                <Form.Control
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="col-md-4 mb-3" controlId="formPoints">
                <Typography>Purchased Points</Typography>
                <Form.Control
                  type="text"
                  value={points}
                  onChange={(e) => setPoints(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="form-row">
              <Form.Group className="col-md-6 mb-3" controlId="formTransactionId">
                <Typography>Transaction Id</Typography>
                <Form.Control
                  type="text"
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="col-md-6 mb-3" controlId="formPurchaseDateTime">
          <Typography>Purchase Date & Time</Typography>
          <Form.Control
            type="datetime-local"
            value={purchaseDate}
            onChange={(e) => setPurchaseDate(e.target.value)}
            max={getCurrentDate} 
          />
        </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="tz-primary-btn reverse"   onClick={handleClose}>
          Cancel
          </Button>
          <Button type='submit' variant="secondary" className="tz-primary-btn" onClick={handleCreatePayment}>
          Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PaymentManagement;


